import Head from "next/head";
import {useTranslation} from "react-i18next";
import {Button} from "antd";
import Link from "next/link";

export default function Page404(){
  const {t} = useTranslation()

  return(
    <>
      <Head>
        <title>{t('error_page_note_found')}</title>
      </Head>
      <div className="bg-neutral-3">
        <div className="relative min-h-screen mx-auto bg-white max-w-passio">
          <div className="absolute w-full px-4 transform -translate-y-1/2 top-1/2 ">
            <div className="w-full mb-3">
              <img className="w-40 h-40 m-auto" src="/images/Frame 14514.png" />
            </div>
            <div className=" flex justify-center flex-col align-middle text-center ">
              {t('error_page_note_found')}
            </div>
            <div className="flex mt-5 justify-center">
              <Link href={'/'}>
                <Button className="rounded-lg"  type={'primary'}>{t('error_backhome')}</Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}